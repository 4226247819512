<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/iotEquipment' }">设备管理</el-breadcrumb-item>
          <el-breadcrumb-item>网关子设备</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="sub-equipment-model">
        <div class="equipment-item" v-for="(item, index) in 8" :key="index">
          <div class="equipment-header">
            <div class="h-name">
              <i class="iconfont icon-wangguanshebei"></i>    
              <span style="margin-left:10px;">超声波冷热量表</span>
            </div>
            <div class="h-time">
              <span>2022-04-12 12:35:25</span>
            </div>
          </div>
          <div class="equipment-middle">
            <div class="middle-params">
              <div>设备号</div>
              <div>SIN-1158S-DB01</div>
            </div>
            <div class="middle-params">
              <div>通讯地址</div>
              <div>01</div>
            </div>
            <div class="middle-params">
              <div>瞬时流量</div>
              <div>7.12（m³/h）</div>
            </div>
            <div class="middle-params">
              <div>瞬时流速</div>
              <div>0.96（m/s）</div>
            </div>
            <div class="middle-params">
              <div>正累积流量</div>
              <div>2082.51（m³）</div>
            </div>
            <div class="middle-params">
              <div>负累积流量</div>
              <div>0（m³）</div>
            </div>
          </div>
        </div>
      </div>
      <div class="block">
        <el-pagination
          @size-change="sizeChange"
          @current-change="pageChange"
          :current-page="page"
          :page-size="size"
          :page-sizes="[8]"
          :pager-count="count"
          background
          :layout="layout"
          :total="total"
        ></el-pagination>
      </div>
      
      <!-- <div class="table-list" style="margin:30px 0 100px;">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="equipmentName" label="设备名称"></el-table-column>
          <el-table-column prop="eid" label="设备号"></el-table-column>
          <el-table-column prop="equipmentNumber" label="通讯地址">
            <template slot-scope="scope">
              {{ scope.row.content.equipmentNumber }}
            </template>
          </el-table-column>
          <el-table-column prop="flowInstantaneous" label="瞬时流量">
            <template slot-scope="scope">
              {{ scope.row.content.flowInstantaneous }}
            </template>
          </el-table-column>
          <el-table-column prop="fluidVelocity" label="瞬时流速">
            <template slot-scope="scope">
              {{ scope.row.content.fluidVelocity }}
            </template>
          </el-table-column>
          <el-table-column prop="positiveCumulativeFlow" label="正累积流量">
            <template slot-scope="scope">
              {{ scope.row.content.positiveCumulativeFlow }}
            </template>
          </el-table-column>
          <el-table-column prop="negativeCumulativeFlow" label="负累积流量">
            <template slot-scope="scope">
              {{ scope.row.content.negativeCumulativeFlow }}
            </template>
          </el-table-column>
          <el-table-column prop="reportTime" label="上报时间">
            <template slot-scope="scope">
              {{ scope.row.reportTime ? dayjs(scope.row.reportTime).format('YYYY-MM-DD HH:mm:ss'):'' }}
            </template>
          </el-table-column>
        </el-table>
        <page v-if="total!==0" :size="size" :page="page" :total="total" v-on:sizeChange="sizeChange" v-on:pageChange="pageChange"></page>
      </div> -->
      <div class="bottom-btn">
        <el-button type="primary" size="small" @click="$router.push('/iotEquipment')">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import page from '@/components/page.vue'
import dayjs from 'dayjs';
export default {
  components: {
    page
  },
  data() {
    return {
      loading: false,
      size: 8,
      page: 1,
      total: 0,
      formInline: {},
      row: {},
      tableData: [
        {
          questionId: 1637035485469,
          result: '正常',
          waterMeterNo: 'LK-5649',
          staffName: ['张威'],
          reportTime: 1637660902000,
          position: '已采集',
        },
        {
          questionId: 1637035485469,
          result: '正常',
          waterMeterNo: 'TP-6548',
          staffName: ['李丹'],
          reportTime: 1637466731000,
          position: '已采集',
        },
        {
          questionId: 1637035485469,
          result: '异常',
          waterMeterNo: 'QW-3456',
          staffName: ['刘娜'],
          reportTime: 1637303655000,
          position: '已采集',
        },
        {
          questionId: 1637035485469,
          result: '正常',
          waterMeterNo: 'LF-2468',
          staffName: ['王涛'],
          reportTime: 1637195713000,
          position: '已采集',
        }
      ],
      sheshi: [],
      staffId: 0,
      roleId: 0
    }
  },
  computed: {
    layout() {
      let str = "total, sizes, prev, pager, next, jumper";
      this.isSizes?str="sizes,"+str:"";
      return str;
    }
  },
  methods: {
    dayjs,
    pageChange(val) {
      this.page = val
      sessionStorage.setItem('currentPage', val)
      this.loadTableData()
    },
    sizeChange(val) {
      this.size = val
      sessionStorage.setItem('currentSize', val)
      this.loadTableData()
    },
    getLastPage() {
      this.page = Number(sessionStorage.getItem('currentPage')) || 1
    },
    getSize() {
      this.size = Number(sessionStorage.getItem('currentSize')) || 50
    },
    loadTableData() {
      this.loading = true
      this.$ajax.post('reportRecordQuery', {
        pageNum: this.page,
        pageSize: this.size,
        eid: this.row.eid
      }).then(res => {
        this.loading = false
        this.tableData = res.data
        this.total = res.total
      }).catch(err=>{
        this.loading = false
      })
    },
  },
  mounted() {
    // this.getLastPage()
    // this.getSize()
    // this.loadTableData()
  },
  destroyed() {
    sessionStorage.removeItem('currentPage')
    sessionStorage.removeItem('currentSize')
  }
}
</script>

<style lang="less" scoped>
.block /deep/{
  /* text-align: center;
    background: #fff;
    margin-top: 10px; */
  /* border: 1px solid #ebeef5 */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
  padding: 0 20px;
  .el-pager li {
    margin: 0 5px;
    border-radius: 2px;
  }
}
.el-dropdown-dis {
  color: #ccc;
  cursor: not-allowed;
}
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.sub-equipment-model {
    // background: #f0f0f0;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.equipment-item {
    width: 21.4%;
    // height: 220px;
    // background: #F0F3F8;//F0F3F8
    box-shadow: 0px 0px 20px rgba(211, 211, 211, .5);
    border: 1px solid #ebeef5;
    border-radius: 15px;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
}
.equipment-item:nth-child(4n) {
    margin-right: 0px;
}
.equipment-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    margin-bottom: 20px;
    font-size: 16px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
    .h-time {
        color: #aaa;
        font-size: 14px;
    }
}
.equipment-middle {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
} 
.middle-params {
    width: 50%;
    padding: 10px 0;
    color: #333;
    font-size: 15px;
    border-radius: 10px;
    text-align: center;
    transition: all .3s ease;
}
.middle-params:hover {
    background: #F3F7FB;
    // background: #d8dbe0;
}
.middle-params div:nth-child(1) {
    color: #999;
    font-size: 13px;
    margin-bottom: 5px;
}

</style>